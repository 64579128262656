export default {
    nav: {
        items: {
            history_btn: 'Stats',
            plan_btn: 'Today',
            forecast_btn: '2-Weeks',
            roadmap_btn: 'Roadmap'
        }
    },

    login: {
        title: 'Train Smart. Become your Best.',
        subtitle: 'No payment details required',
        or: 'or',

        terms: 'Terms and Conditions',
        terms_hint: 'By continuing you accept our',

        already_have_an_account: 'Already have an account?',
        login_btn: 'Log in',

        apple_btn: 'Continue with Apple',
        google_btn: 'Continue with Google',
        facebook_btn: 'Continue with Facebook',
        triq_btn: 'Continue with Email'
    },

    terms: {
        title: 'Terms and Conditions',
        previous_btn: 'Back',
    },

    privacy: {
        title: 'Privacy Policy',
        previous_btn: 'Back',
    },

    onboarding: {
        email: {
            title: 'Please enter a valid Email',
            subtitle: 'To keep you updated regularly, please provide a valid Email address below.',
            confirm_btn: 'Confirm',
            skip_btn: 'Skip',

            form: {
                input: {
                    email: {
                        label: 'Email Address',
                        placeholder: "e.g. jonas.d{'@'}gmail.com"
                    }
                }
            }
        },

        connect: {
            title: 'Connect Account',
            subtitle: 'Connect TRIQ to your wearable account',

            description: 'This jumpstart your plan and lets TRIQ track your future training. To continue, this step is required.',

            failed_msg: 'We failed to retrieve your GARMIN® Connect data, please try again.',
            already_connected_hint: 'Seems your GARMIN® account has already been connected. You can disconnect your account or just continue with your connected GARMIN® account.',

            confirm_btn: 'Confirm',
            abort_btn: 'I don\'t have GARMIN®',
            disconnect_btn: 'Disconnect my Garmin account',
            continue_btn: 'Continue',
            dont_have_garmin_btn: 'I do not have',

            garmin: {
                title: 'Processing',
                description: 'Please wait while connecting to GARMIN®',

                signature: 'Your TRIQ team',

                success: {
                    title: 'Success',
                    description: 'We are now connected to your GARMIN® account, we are starting to import and process your data',
                    next_btn: 'Next'
                },

                hint: 'The GARMIN® data import is subject to restrictions:<ul><li>we can only collect the last 5 years of sports activities and last 3 years of health data.</li><li>we can only collect data that was recorded with GARMIN® Devices.</li><li>we only account for Swim, Bike, Run activities for now.</li></ul>Find out more in our <a href="{faqUrl}" style="color: white; text-decoration: underline;">FAQ</a>.',

                restrictions: {
                    previous_btn: 'Previous',
                    title: 'GARMIN® import restrictions',
                    description: '<p>The import of past data and the syncing of future data is subject of the following restrictions by GARMIN®</p><ul class="info-list"><li>Data import or sync is restricted to activities recorded with GARMIN® devices. Unfortunately, workouts logged in third-party environments, such as Zwift, cannot be imported due to GARMIN®\'s restrictions.</li><li>Our servers currently only display and analyze Swim, Bike, and Run activities. Complemetary triathlon sports coming soon.</li><li>Data import is limited to the last 5 years for sports activities and the last 3 years for health data from GARMIN® devices.</li></ul><p>Find out more in our <a href="{faqUrl}" target="_blank" class="text-grey" style="text-decoration: underline;">FAQ</a>.</p>',
                    continue_btn: 'Got it'
                }
            },

            abort: {
                title: 'Which wearable do you have?',
                description: '<p>We\'ll support more wearable devices soon and let you know.</p><p class="body_3">Your TRIQ Team</p>',
                previous_btn: 'Previous',
                logout_btn: 'Log out',
                garmin_hint: 'We currently ONLY support GARMIN®. Tell us your email to notify you when other services are available.',
                notify_me_btn: 'Notify me',

                form: {
                    input: {
                        other: {
                            label: '',
                            placeholder: 'Wearable name'
                        },
                        email: {
                            label: 'Notify me via Email',
                            placeholder: "jonas.d{'@'}gmail.com"
                        }
                    }
                },

                complete: {
                    title: 'Thanks!',
                    description: '<p>We will notify you as soon as we are ready to provide service for your choice of wearable or using TRIQ without wearable.</p><p>Visit our website for more information.</p>',
                    continue_btn: 'Go to website'
                }
            }
        },

        steps: {
            previous_btn: 'Previous',
            continue_btn: 'Continue',

            about_you: 'About You and Your Goals',
            training_constraints: 'Training Constraints',
            swim_insights: 'Swim Insights',
            run_insights: 'Run Insights',
            bike_insights: 'Bike Insights'
        },

        profile: {
            title: 'Complete your personal info',
            subtitle: 'We found these personal info from GARMIN®.',

            form: {
                input: {
                    username: {
                        label: 'Nickname (optional)',
                        placeholder: 'e.g. Phil'
                    },
                    gender: {
                        label: 'Gender',
                        placeholder: 'Please select gender',

                        option: {
                            male: 'Male',
                            female: 'Female'
                        }
                    },
                    date_of_birth: {
                        label: 'Date of Birth'
                    },
                    height: {
                        label: 'Height (optional)',
                        label_metric: 'Height in cm (optional)',
                        label_imperial: 'Height in inches (optional)',
                    },
                    weight: {
                        label: 'Weight',
                        label_metric: 'Weight in kg',
                        label_imperial: 'Weight in lbs',
                    },
                    location: {
                        label: 'Location (optional)'
                    },
                    unit: {
                        label: 'Unit',
                        placeholder: 'Please select unit'
                    }
                }
            },

            gender_hint: 'We respect the diversity of sexual identities. However, for training purposes, we need to understand your physiological capabilities.',

            confirm_btn: 'Confirm',
            previous_btn: 'Previous'
        },

        intention: {
            title: 'What is your main intention?',
            subtitle: 'Please choose the most accurate scenario, this will help us custom your training.',

            group: {
                beginner: 'Beginner',
                advanced: 'Advanced'
            },

            form: {
                input: {
                    intention: {
                        label: '',

                        options: {
                            i1: 'I\'m new to triathlon and need a plan.',
                            i2: 'I am used to triathlon but need a ramp up first as I am coming from a long break. (more than 6 weeks)',
                            i3: 'Currently, I\'m in training shape and would like to chase a different race distance than I\'m used to.',
                            i4: 'I already use my maximal training availability and would like to optimise my training.'
                        }
                    }
                }
            },

            previous_btn: 'Previous',
            confirm_btn: 'Confirm'
        },

        goal: {
            arace: {
                title: 'Target Race Information',

                form: {
                    input: {
                        name: {
                            label: 'Race Name',
                            placeholder: 'Enter',
                        },
                        location: {
                            label: 'City or Country (Optional)',
                            placeholder: 'Enter'
                        },
                        date: {
                            label: 'Race Date'
                        },
                        race_type: {
                            label: 'Race Type',
                            placeholder: 'Select'
                        },
                        topography: {
                            label: 'Topography (Optional)',
                            placeholder: 'Select'
                        },
                        aimedTargetTime: {
                            label: 'Target Time',
                            placeholder: 'Select'
                        }
                    }
                },

                info_msg: 'Enter hypothetical infos if you haven\'t planned a race yet but would like to start training.',
                disclaimer_msg: 'Disclaimer: We’ll let you know later if this objective is reasonable.',

                previous_btn: 'Previous',
                confirm_btn: 'Confirm'
            }
        },

        racing_history: {
            previous_btn: 'Previous',
            confirm_btn: 'Confirm',
            skip_btn: 'Skip',

            title: 'What best represents<br>your race history?',
            further_details_hint: 'You can always add further race details later in the settings section which will help us improve our service.',

            form: {
                input: {
                    racing_history: {
                        label: ' ',

                        options: {
                            ParticipatedInTriathlonLongerThanOnboardingGoal: 'I have already participated in {a} {targetRaceDistance} triathlon or longer.',
                            ParticipatedInTriathlonShorterThanOnboardingGoal: 'I have already participated in a shorter race than {a} {targetRaceDistance} triathlon.',
                            DidNotParticipateInTriathlon: 'I did not participate in a triathlon race yet.'
                        }
                    },

                    racing_history_date: {
                        label: 'Race Date'
                    }
                }
            }

        },

        endurance_experience: {
            swimming: {
                previous_btn: 'Previous',
                confirm_btn: 'Confirm',
                dontknow_btn: 'I don\'t know',

                title: 'For how long have you been swimming consistently?',
                description: 'Consistently means on a weekly basis without a break of more than 6 weeks. The offseason doesn’t count as a break.',
                consistently_hint: 'Analyzed with data from GARMIN®',
                volume_hint: 'Analyzed with data from GARMIN®',
                threshold_hint: 'If you do not know your CSP exactly try to estimate, on a distance of 1000-1500m, how fast you can swim on avg. (mm:ss/100m) or try to estimate your avg. 100m time, if you would do 10x100m with few sec rest in between. If you do not know at all TRIQ will set a default value of 2:00min/100m and will plan a Swim Test as soon as possible.',
 
                volume_title: 'What distance have you swum during this period?',

                volume: {
                    title: 'What (avg.) weekly distance did you swim in the past 6 weeks?',
                    description: ''
                },

                volume_weeks: {
                    title: 'What (avg.) distance did your longest swims in the past 6 weeks have?',
                    description: ''
                },

                threshold: {
                    title: 'What is your Critical Swim Pace?',
                    description: 'The critical swim pace (CSP) is defined as the avg. speed per 100m or 100yd a swimmer can maintain over a distance of 1000m or 1000yd without experiencing significant fatigue.<br>If you do not know your CSP or have difficulties to estimate it, we will set a value of 2:00min and will plan a swim test as soon as possible.'
                },

                form: {
                    input: {
                        swimmingExperienceTrainingDurationInMonths: {
                            label: '',
                            placeholder: '-'
                        },
                        swimmingExperienceTrainingVolumeInMetersPerWeek: {
                            label: '',
                            placeholder: '-'
                        },
                        averageDistanceOfLongSwimmingSession: {
                            label: '',
                            placeholder: '-'
                        },
                        css: {
                            label: '',
                            placeholder: '-'
                        }
                    }
                }
            },

            cycling: {
                previous_btn: 'Previous',
                confirm_btn: 'Confirm',
                dontknow_btn: 'I don\'t know',

                title: 'For how long have you been cycling consistently?',
                description: 'Consistently means on a weekly basis without a break of more than 6 weeks. The offseason doesn’t count as a break.',
                consistently_hint: 'Analyzed with data from GARMIN®',
                volume_hint: 'Analyzed with data from GARMIN®',

                volume_title: 'What was your cycling volume during this period?',

                volume: {
                    title: 'How many (avg.) weekly hours did you cycle in the past 6 weeks?',
                    description: ''
                },

                volume_weeks: {
                    title: 'What (avg.) duration did your longest bike rides in the past 6 weeks have?',
                    description: ''
                },

                threshold: {
                    hr: {
                        title: 'What are your heart rate thresholds for cycling?',
                        description: ''
                    },
                    power: {
                        title: 'What are your power thresholds for cycling?',
                        description: ''
                    }
                },

                dontknow: 'I don\'t know',

                form: {
                    input: {
                        cyclingExperienceTrainingDurationInMonths: {
                            label: '',
                            placeholder: '-'
                        },
                        cyclingExperienceTrainingVolumeInHoursPerWeek: {
                            label: '',
                            placeholder: '-'
                        },
                        averageDurationOfLongCyclingSession: {
                            label: '',
                            placeholder: '-'
                        },

                        hr: {
                            upper: {
                                label: 'Upper Threshold (bpm)',
                                placeholder: '-'
                            },
                            lower: {
                                label: 'Lower Threshold (bpm)',
                                placeholder: '-'
                            },
                        },

                        power: {
                            upper: {
                                label: 'Upper Threshold (Watts)',
                                placeholder: '-'
                            },
                            lower: {
                                label: 'Lower Threshold (Watts)',
                                placeholder: '-'
                            },
                        }
                    }
                }
            },

            running: {
                previous_btn: 'Previous',
                confirm_btn: 'Confirm',
                dontknow_btn: 'I don\'t know',

                title: 'For how long have you been running consistently?',
                description: 'Consistently means on a weekly basis without a break of more than 6 weeks. The offseason doesn’t count as a break.',
                consistently_hint: 'Analyzed with data from GARMIN®',
                volume_hint: 'Analyzed with data from GARMIN®',

                volume_title: 'What was your running volume during this period?',

                volume: {
                    title: 'How many (avg.) weekly hours did you run in the past 6 weeks?',
                    description: ''
                },

                volume_weeks: {
                    title: 'What (avg.) duration did your longest runs in the past 6 weeks have?',
                    description: ''
                },

                threshold: {
                    hr: {
                        title: 'What are your heart rate thresholds for running?',
                        description: ''
                    },
                    pace: {
                        title: 'What are your pace thresholds for running?',
                        description: ''
                    }
                },

                dontknow: 'I don\'t know',

                form: {
                    input: {
                        runningExperienceTrainingDurationInMonths: {
                            label: '',
                            placeholder: '-'
                        },
                        runningExperienceTrainingVolumeInHoursPerWeek: {
                            label: '',
                            placeholder: '-'
                        },
                        averageDurationOfLongRunningSession: {
                            label: '',
                            placeholder: '-'
                        },

                        hr: {
                            upper: {
                                label: 'Upper Threshold (bpm)',
                                placeholder: '-'
                            },
                            lower: {
                                label: 'Lower Threshold (bpm)',
                                placeholder: '-'
                            },
                        },

                        pace: {
                            upper_metric: {
                                label: 'Upper Threshold (min/km)',
                                placeholder: '-'
                            },
                            lower_metric: {
                                label: 'Lower Threshold (min/km)',
                                placeholder: '-'
                            },
                            upper_imperial: {
                                label: 'Upper Threshold (min/mi)',
                                placeholder: '-'
                            },
                            lower_imperial: {
                                label: 'Lower Threshold (min/mi)',
                                placeholder: '-'
                            },
                        }
                    }
                }
            },

            other: {
                previous_btn: 'Previous',
                confirm_btn: 'Confirm',

                title: 'Within this period, have you consistently practiced any other endurance sports?',
                consistently_hint: 'Consistently means twice a week or more.'
            },

            maf: {
                previous_btn: 'Previous',
                confirm_btn: 'Confirm',
                title: 'In order to estimate your lower HR-Threshold please answer the following',
                hint: 'Concept based on Dr. Phil Maffetone',
                result: 'Resulting MAF HR:',

                form: {
                    input: {
                        maf: {
                            options: {
                                i1: 'I am recovering from major illness or chronic overtraining',
                                i2: 'I am injured, regressed, not improving or inconsistent',
                                i3: 'I am training consistently for up to 2 years without problems',
                                i4: 'I am improving without injury for more than 2 years'
                            },
                            option_descriptions: {
                                i1: 'If you have or are recovering from a major illness (heart disease, any operation or hospital stay, etc.), are in rehabilitation, are on any regular medication, or are in Stage 3 (chronic) overtraining (burnout). This will subtract an additional 10 bpm.',
                                i2: 'If you are injured, have regressed or not improved in training (such as poor MAF Tests) or competition, get more than two colds, flu or other infections per year, have seasonal allergies or asthma, are overfat, are in Stage 1 or 2 of overtraining, or if you have been inconsistent, just starting, or just getting back into training. This will subtract an additional 5 bpm.',
                                i3: 'If you have been training consistently (at least four times weekly) for up to two years without any of the problems mentioned in a) or b), no modification is necessary. This will calculate MAF as 180 minus your age.',
                                i4: 'If you have been training for more than two years without any of the problems listed above, have made progress in your MAF Tests, improved competitively and are without injury. This will add 5 bpm.'
                            }
                        }
                    }
                }
            },

            upper_pace_threshold_calculator: {
                previous_btn: 'Previous',
                confirm_btn: 'Calculate threshold',
                dontknow_btn: 'I don\'t know',

                title: 'Upper Pace Threshold Calculator',
                description: 'Please select a distance you know your potential race time.',

                form: {
                    input: {
                        runningSpeedThresholdDistance: {
                            label: 'Distance',
                            placeholder: '-'
                        },
                        raceTimeInSeconds: {
                            label: 'Race Time',
                            placeholder: 'hh:mm:ss'
                        }
                    }
                }
            }
        },

        injury: {
            previous_btn: 'Previous',
            confirm_btn: 'Confirm',
            skip_btn: 'I\'m all good',

            title: 'Injury Tendencies',
            description: 'For our training ramping and sport-split management it is important to tell us if you tend to injuries or encounter physical limitations.'
        },

        training_volume: {
            previous_btn: 'Previous',
            confirm_btn: 'Confirm',

            title: 'Set your maximum training volume',
            subtitle: 'It’s the peak amount of time<br>you´re willing to train per week.',
            subtitle2: 'It’s the amount of time you´re<br>willing to train per week.',

            form: {
                input: {
                    hoursPerWeekAvailable: {
                        label: ' ',
                        placeholder: 'Select'
                    }
                }
            }
        },

        availability: {
            previous_btn: 'Previous',
            confirm_btn: 'Confirm',
            later_btn: 'Adjust later',

            title: 'Adjust your availability ',
            subtitle: 'Please select duration and modalities.',

            info: {
                title: 'Availability Requirements',
                description: 'The following requirements are recommended for optimal training planning when reaching the maximum training volume.',

                availability_optimal: 'Optimal Availability',
                availability_suboptimal: 'Suboptimal Availability',

                validation: {
                    total_volume_per_sport: {
                        title: 'Min. Total Volume per Sport',
                        description: 'The minimum required training volume per sport is based on your race goal. For TRIQ to be able to plan optimally, please provide about 10% extra availability (example: your peak training volume is 10 hours, then please provide at least 11 hours of availability).'
                    },
                    slots_per_sport: {
                        title: 'Min. Number of Slots per Sport',
                        description: 'To ensure good training principles, please provide at least three weekly training slots per sport, if possible.'
                    },
                    long_workouts: {
                        title: 'Min. Duration for Long Workouts',
                        description: 'Based on your race goal, TRIQ requires a minimum duration for long workouts. Therefore, please provide at least one weekly training slot for bike and run that fulfills the duration listed below.'
                    }
                },

                table: {
                    header: {
                        race_goal: 'Race Goal',
                        modality: 'Modality',
                        time: 'Time'
                    },
                    row: {
                        sprint: 'Sprint',
                        olympic: 'Olympic',
                        half: 'IM 70.3',
                        ironman: 'Ironman'
                    }
                }
            },

            warning_modal: {
                title: 'Availability Warning',
                description: 'The minimum availability constraints are not met and therefor TRIQ cannot guarantee an optimal coaching.',
                confirm_btn: 'Continue anyway',
                cancel_btn: 'Go back'
            }
        },

        notifications: {
            previous_btn: 'Previous',
            title: 'Communications',
            push: {
                title: 'Push Notifications',
                description: '<p>I\'m happy to receive occasional and relevant information along the way to help me get the best of TRIQ</p>For example:<br> - New threshold detection<br> - Fatigue warnings<br> - Plan adaptions<br> - Coaching reminders'
            },
            product: {
                title: 'Product updates & subscriptions ',
                description: 'I’m happy for TRIQ to occasionally keep me informed by email about new offers, product and services.'
            },
            accept_btn: 'Accept',
            reject_btn: 'Do no accept',
            continue_btn: 'Continue',
            permission_rejected: 'Permission for push notifications has been rejected. You need to change this in your phone settings.'
        },

        feasibility_check: {
            signature: 'Your TRIQ team',
            next_btn: 'Next',
            previous_btn: 'Previous',

            GoalSeemsFeasible: {
                title: 'You can do it!<br>You goal seems feasible to us.',
                description: '<p>Based on your race distance, your race experience and event date this seams feasible to us.</p><p>Remember to train continuously, as this has the biggest impact on your fitness.</p>'
            },
            GoalIsDoable: {
                title: 'This will be challenging<br>but doable.',
                description: '<p>You\'ll need to be highly committed and keep training consistently.</p><p>Your goal seems pretty optimistic to us. Keep pushing and we’ll get back to you with more relevant recommendations as soon as we have more data.</p>',
                warning_hint: 'You’ll always be able to change the race constraints in the settings.'
            },
            GoalDateTooClose: {
                title: 'We can’t determine your [70.3] race feasibility with your current race date.',
                description: '<p>Your race date is too close for us to make an adequate evaluation. We set the plan duration for an [70.3] to at least [18weeks].</p><p>However you’ll be able to access a training plan. Remember, it takes time to develop the necessary endurance and strength capacity.</p>',
                warning_hint: 'You\'ll always be able to change the race constraints in the settings.'
            },
            GoalInTaperPhase: {
                title: 'Your race is too soon and<br>overlaps with our taper phase.',
                description: '<p>Our standard taper phase lasts from 1 to 2 weeks and is designed to allow the body to rest and recover from the training.</p><p>This phase is not individualised so you won’t experience the full potential of our app.</p>',
                error_hint: 'You\'ll always be able to change the race constraints in the settings.'
            }
        },

        complete: {
            title: 'Onboarding Completed',
            description: 'Thank you for your patience. Based on the provided information, we will now tailor a personalized training plan for you.',
            signature: 'Your TRIQ team',
            processing_btn: 'Processing',
            continue_btn: 'Got it'
        }
    },

    checkin: {
        intro: {
            title: 'When did you get up?',
            description: 'If you are already awake for more than one hour or had a coffee, the HRV measurement is no longer reliable. Please be honest, to make your measurements as acurate as possible.',
            next_btn: 'I Just woke up',
            skip_btn: 'I am up For a while (skip)',
            previous_btn: 'Cancel'
        },

        device_select: {
            title: 'Select your HR-Measurement Device',
            previous_btn: 'Previous',

            device: {
                triq_phone: {
                    description: 'Measure with phone'
                },
                triq_chest_belt: {
                    description: 'Measure with chest-belt'
                },
                oura_ring: {
                    description: 'Measure with Oura'
                }
            },

            info_msg: 'Other 3rd party measurement options coming soon! '
        },

        hrv_measurement: {
            title: 'Start HRV',
            description: 'Cover the camera completely with your finger. Measuring will start automatically. Please do the measurement at rest, preferably while sitting.',
            previous_btn: 'Cancel',
            start_btn: 'Start',
            skip_btn: 'Skip finger scan',

            failed_msg: 'HRV measurement failed. Please try again later.',
            analyzing_title: 'We are analyzing your<br>Heart Rate data',

            measuring: {
                title: 'Keep camera covered',
                description: 'Try to relax and breathe normally. Cover the camera completely with your finger and reduce movement to a minimum.'
            },

            success: {
                title: 'Measurement successful',
                continue_btn: 'Continue',
                remasure_btn: 'Remeasure',

                hrv: 'HRV',
                restinghr: 'RHR',
            },

            failed: {
                title: 'Something went wrong',
                description: 'HRV measurement was not successfull, do you want to remeasure?',
                previous_btn: 'Cancel',
                remeasure_btn: 'Remeasure',
                skip_btn: 'Skip measurement'
            }
        },

        orthopedic_fitness: {
            title: 'Orthopedic fitness',
            question: 'How intense is your perceived muscle fatigue?',
            previous_btn: 'Cancel',
            confirm_btn: 'Confirm',

            arm_shoulder_soreness: 'Arms & Shoulders',
            core_soreness: 'Core',
            leg_soreness: 'Legs',

            low: 'Low',
            sore: 'Sore',

            failed_msg: 'Failed to update orthopedic fitness'
        },

        questionaire: {
            previous_btn: 'Previous',
            question_fatigue: 'How energyzed do you feel today?',
            question_stress: 'How stressed do you feel today?',
            question_sleep: 'How was your sleep quality last night?',
            question_mood: 'How is your mood today?',

            confirm_btn: 'Continue',
            skip_btn: 'Skip',
        },

        result: {
            dashboard_btn: 'Back to Dashboard',

            proceed: {
                title: 'Proceed as planned',
                description: 'Your cardio values are within normal range and your subjective scores are looking positve.'
            },

            takecare: {
                title: 'Proceed carefully',
                description: 'We detected 3 measurements in a row that are outside the 60-day reference corridor.'
            },

            rest: {
                title: 'Rest day recommended',
                description: 'Your 7-day HRV baseline is outside the 60-day reference corridor.'
            },

            questionaire: {
                title: 'Wellbeing Questions <span class="text-blue">(Beta)</span>',
                fatigue: 'Fatigue',
                stress: 'Stress level',
                sleep: 'Sleep quality',
                mood: 'Mood'
            },

            dataquality: {
                title: 'Data Quality',

                low: 'Low data quality',
                medium: 'Medium data quality',
                high: 'High data quality'
            },

            details: {
                title: 'More details',
            }
        },

        hrv: {
            widget: {
                title: 'HRV',

                today: 'Today:',
                baseline7days: '7-day baseline:',
                baseline7days_notavailable: '7-day baseline: not enough data',
                corridor60days: '60-day corridor',
                corridor60days_notavailable: '60-day corridor: not enough data',
            }
        },

        muscle_fatigue: {
            previous_btn: 'Back',

            title: 'What is your perceived muscle fatigueness?',
            description: 'Your feedback helps us assessing your recovery ability and allocating the planned workouts in an optimal way.',
            info_hint: 'You can report your subjective muscle fatigueness anytime during the day. It is not dependant on the workout schedule.',

            modal: {
                title: 'Muscle Fatigue',
                description: 'How intense is your perceived muscle fatigue?',

                confirm_btn: 'Confirm'
            },

            info_modal: {
                low_title: 'Low muscle fatigue:',
                low_description: 'Is a mild sensation of tiredness experienced in the muscles after physical activity. It is characterized by a manageable level of weariness that typically does not hinder normal functioning.',
                high_title: 'High muscle fatigue:',
                high_description: 'Stemming from intense physical exertion, induces profound exhaustion and muscle soreness, impairing functionality. It results from glycogen depletion, lactic acid buildup, and muscle fiber damage. Recovery requires rest, hydration, and nutrition.',
                confirm_btn: 'Got it'
            },

            arm_shoulder_soreness: 'Arms & Shoulders',
            leg_soreness: 'Legs',

            low: 'Low',
            high: 'High',
            sore: 'Sore',

            success_msg: 'Muscle fatigue has successfully been updated.',
            failed_msg: 'Failed to update muscle fatigue.',
            hint: '<p>Report your muscle fatigue during your workouts.</p><p>Your feedback helps TRIQ to adjust the recovery model to your individual  dynamic and understanding your fitness.</p>',

            thanks_btn: 'Thanks',
            skip_btn: 'Don’t advise me again'
        }
    },

    goal: {
        arace: {
            title: 'Goal',
            update: {
                success_msg: 'Goal has successfully been updated',
            }
        },

        reminder: {
            modal: {
                title: 'Please select a goal',
                description: 'Currently there is no race defined to train for. After the post-race-recovery phase no trainings will be planned. Please set a racegoal. Thank you.',
                confirm_btn: 'Select goal'
            }
        }
    },

    history: {
        index: {
            title: 'Statistics',
            tabs: {
                '1month': '1 Month',
                '3months': '3 Months',
                '1year': '1 Year',
                'all': 'All time'
            },
            duration: 'Duration',
            distance: 'Distance',
            loading: 'Loading …',

            performance_development: {
                title: 'Performance Development',
                more_btn: 'See more'
            },

            completion_rate: {
                title: 'Completion Rate',
                more_btn: 'See more',
                workouts: 'Workouts',
                duration: 'Duration',
                zones: 'Zones'
            },

            latest_workouts: {
                title: 'Last workouts',
                more_btn: 'See all',
                none_hint: 'You haven\'t completed any workouts yet'
            },

            cardio: {
                title: 'Cardio values',
                more_btn: 'See history',

                last_hrv_measurement: 'Last HRV measurement',
                hrv_baseline: '7-day HRV baseline',
                corridor: '60-day corridor',
            }
        },

        duration: {
            title: 'Duration',
            previous_btn: 'Previous',
            tabs: {
                '1month': '1 Month',
                '3months': '3 Months',
                '1year': '1 Year',
                'all': 'All time'
            },
            loading: 'Loading …',

            total_duration: {
                title: 'Total Duration'
            },

            sport_split_by_duration: {
                title: 'Sport Split by Duration',
                swim: 'Swim',
                bike: 'Bike',
                run: 'Run',
            },

            long_duration_evolution: {
                title: 'Long Duration Evolution',
            },

            longest_workout: {
                title: 'Longest Workout',
            }
        },

        distance: {
            title: 'Distance',
            previous_btn: 'Previous',
            tabs: {
                '1month': '1 Month',
                '3months': '3 Months',
                '1year': '1 Year',
                'all': 'All time'
            },
            loading: 'Loading …',

            total_distance: {
                title: 'Total Distance'
            },

            sport_split_by_distance: {
                title: 'Sport Split by Distance',
                swim: 'Swim',
                bike: 'Bike',
                run: 'Run',
            },

            long_distance_evolution: {
                title: 'Long Distance Evolution',
            },

            longest_workout: {
                title: 'Longest Workout',
            }
        },

        performance_development: {
            title: 'Performance Development',
            previous_btn: 'Previous',
            loading: 'Loading …',
            tabs: {
                '1month': '1 Month',
                '3months': '3 Months',
                '1year': '1 Year',
                'all': 'All time'
            },
            no_data: {
                title: 'No test data available yet',
                description: 'We will schedule Threshold testing in the next few days or weeks. As soon as we have your first results, you can have a look at your performance development here.'
            },
            latest_performance_tests: {
                title: 'Latest Performance Tests',
            }
        },

        completion_rate: {
            title: 'Completion Rate',
            previous_btn: 'Previous',
            loading: 'Loading …',
            tabs: {
                '1month': '1 Month',
                '3months': '3 Months',
                '1year': '1 Year',
                'all': 'All time'
            },
            workouts: {
                title: 'Workouts'
            },
            duration: {
                title: 'Duration'
            },
            training_zones: {
                title: 'Training Zones'
            },
            info: {
                title: 'Completion Rate of Training Zones',
                description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,<br>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, '
            }
        },

        workouts: {
            title: 'Executed Workouts',
            previous_btn: 'Previous',
            loading: 'Loading …',
            none_hint: 'You haven\'t completed any workouts yet',
        }
    },

    notification: {
        index: {
            previous_btn: 'Back',
            title: 'Notifications',

            tabs: {
                feedback: 'Feedback',
                other: 'Other'
            },

            delete_hint: 'We will delete your notifications after 72 hours',
            none_hint: 'No notifications yet',

            delete: {
                success_msg: 'Notification has successfully been deleted',
                error_msg: 'Failed to delete notification',
                failed_msg: 'Failed to delete notification'
            }
        },

        item: {
            action: {
                delete_btn: 'Delete'
            }
        }
    },

    settings: {
        tab: {
            trainingplan: 'Training Plan',
            app: 'App',
            profile: 'Profile',
        },

        index: {
            title: 'Settings',

            menu: {
                trainingplan: {
                    item: {
                        goal_availability: 'Goal & Availability',
                        training_zones_thresholds: 'Training Zones & Thresholds',
                        training_volume_capability: 'Training Volume Capability',
                        long_session_capability: 'Long Session Capability',
                        injury_tendencies: 'Injury Tendencies',
                        units_of_measurement: 'Units of Measurement'
                    }
                },
                app: {
                    item: {
                        notification_preferences: 'Notification Preferences',
                        third_party_connections: '3rd Party Connections',
                        support: 'Support and Feedback',
                        faq: 'FAQ',
                        glossary: 'Glossary',
                        privacy_terms: 'Privacy & Terms',
                        privacy: 'Privacy Policy',
                        terms: 'Terms & Conditions',
                        about: 'About TRIQ'
                    }
                },
            }
        },

        training_volume_capability: {
            previous_btn: 'Back',
            title: 'Weekly Training Capability',
            description: 'TRIQ is currently planning for the upcoming week with weekly volume capabilities automatically adjusted based on your training progress. You can manually adjust these values if you find them too high or too low, overriding our recommendation for the upcoming week.',
            history_btn: 'See Change History',
            total_volume_hint: 'Your Total Training Volume Capability is {volume} hours',
            confirm_btn: 'Confirm',
            success_msg: 'Training Volume Capability has successfully been updated',
            failed_msg: 'Failed to update Training Volume Capability',

            form: {
                input: {
                    swimmingExperienceTrainingVolumeInMetersPerWeek: {
                        label: 'Swim',
                        placeholder: '-'
                    },
                    cyclingExperienceTrainingVolumeInHoursPerWeek: {
                        label: 'Bike',
                        placeholder: '-'
                    },
                    runningExperienceTrainingVolumeInHoursPerWeek: {
                        label: 'Run',
                        placeholder: '-'
                    },
                }
            },

            history: {
                previous_btn: 'Back',
                title: 'Change History',
                description: 'This Week\'s Training Volume',
                source: {
                    Onboarding: 'initial',
                    UserChanged: 'user change'
                }
            }
        },

        long_session_capability: {
            previous_btn: 'Back',
            title: 'Long Session Capability',
            description: 'TRIQ is currently planning for the upcoming week with long session capabilities automatically adjusted based on your training progress. You can manually adjust these values if you find them too high or too low, overriding our recommendation for the upcoming week.',
            history_btn: 'See Change History',
            confirm_btn: 'Confirm',
            success_msg: 'Long Session Capability has successfully been updated',
            failed_msg: 'Failed to update Long Session Capability',

            form: {
                input: {
                    averageDurationOfLongCyclingSession: {
                        label: 'Bike',
                        placeholder: '-'
                    },
                    averageDurationOfLongRunningSession: {
                        label: 'Run',
                        placeholder: '-'
                    },
                }
            },

            history: {
                previous_btn: 'Back',
                title: 'Change History',
                description: 'This Week\'s Long Session',
                // source: {
                //     Onboarding: 'initial',
                //     UserChanged: 'user change'
                // }
            }
        },

        injury_tendencies: {
            previous_btn: 'Back',
            title: 'Injury Tendencies',
            description: 'For our training ramping and sport-split management it is important to tell us if you tend to injuries or encounter physical limitations.',
            confirm_btn: 'Confirm',
            skip_btn: 'I\'m all good',
            success_msg: 'Injury Tendencies have successfully been updated',
            failed_msg: 'Failed to update Injury Tendencies',

            option: {
                swimming: 'I tend to swimming injuries',
                cycling: 'I tend to cycling injuries',
                running: 'I tend to running injuries'
            }
        }
    },

    profile: {
        index: {
            previous_btn: 'Dashboard',
            title: 'Profile',

            menu: {
                item: {
                    athlete_info: 'Athlete Info',
                    subscription: 'Subscription',
                    goal_availability: 'Goal & Availability',
                    training_volume: 'Current Training Volume',
                    max_training_duration: 'Long Workouts Durations',
                    training_zones_thresholds: 'Training Zones & Thresholds',
                    hr_measurement_trends: 'HR-Measurement Trends',
                    equipment: 'Your Equipment'
                }
            },

            logout_btn: 'Log out',
            recalculate_btn: 'Reset Training Plan'
        },

        personal: {
            athlete_information: {
                previous_btn: 'Profile',
                title: 'Athlete Information',
                profile_picture: {
                    edit_btn: 'Edit',
                    delete_btn: 'Delete'
                },
                account_information: {
                    title: 'Account information',
                    nickname: 'Nickname',
                    firstname: 'First Name',
                    lastname: 'Last Name',
                    email: 'Email',
                    password: 'Password',
                    height: 'Height',
                    weight: 'Weight',
                    date_of_birth: 'Date of Birth',
                    gender: 'Gender',
                    delete_account: 'Delete Account',
                    delete_account_modal: {
                        title: 'Delete Account?',
                        description: 'Are you sure you want to delete your TRIQ account?',
                        confirm_btn: 'Delete account',
                        cancel_btn: 'Go back'
                    },
                    identity_provider_hint: 'Your account is currently connected via {identityProvider}.'
                },
                athlete_type: {
                    title: 'Athlete basics'
                },
                edit: {
                    password: {
                        previous_btn: 'Back',
                        success_msg: 'Password has  successfully been updated',
                        failed_msg: 'Failed to update password. Please try again',
                        old_password: {
                            label: 'Current Password'
                        },
                        new_password: {
                            label: 'New Password'
                        },
                        new_password_confirmation: {
                            label: 'Confirm New Password'
                        },
                        password_policy_hint: 'Password must have at least 10 characters, have lowercase, uppercase and digit.',
                        update_btn: 'Change password'
                    },
                    email: {
                        previous_btn: 'Back',
                        label: 'Email',
                        success_msg: 'Email has successfully been updated',
                        failed_msg: 'Failed to update email'
                    },
                    nickname: {
                        previous_btn: 'Back',
                        label: 'Nickname',
                        success_msg: 'Nickname has successfully been updated',
                        failed_msg: 'Failed to update Nickname'
                    },
                    firstname: {
                        previous_btn: 'Back',
                        label: 'First Name',
                        success_msg: 'First Name has successfully been updated',
                        failed_msg: 'Failed to update First Name'
                    },
                    lastname: {
                        previous_btn: 'Back',
                        label: 'Last Name',
                        success_msg: 'Last Name has successfully been updated',
                        failed_msg: 'Failed to update Last Name'
                    },
                    height: {
                        previous_btn: 'Back',
                        label: 'Height',
                        label_metric: 'Height in cm',
                        label_imperial: 'Height in inches',
                        success_msg: 'Height has successfully been updated',
                        failed_msg: 'Failed to update height'
                    },
                    weight: {
                        previous_btn: 'Back',
                        label: 'Weight',
                        label_metric: 'Weight in kg',
                        label_imperial: 'Weight in lbs',
                        success_msg: 'Weight has successfully been updated',
                        failed_msg: 'Failed to update weight'
                    },
                    date_of_birth: {
                        previous_btn: 'Back',
                        label: 'Date of Birth',
                        success_msg: 'Date of birth has successfully been updated',
                        failed_msg: 'Failed to update date of birth'
                    },
                    gender: {
                        previous_btn: 'Back',
                        label: 'Gender',
                        success_msg: 'Gender has successfully been updated',
                        failed_msg: 'Failed to update gender'
                    },
                    profile_picture: {
                        previous_btn: 'Back',
                        title: 'Profile picture'
                    }
                }
            },

            goal_and_availability: {
                previous_btn: 'Back',
                index: {
                    title: 'Goal & Availability',
                    edit_btn: 'Edit',

                    goal: {
                        title: ' Goal',
                        race_target_time: 'RACE TARGET TIME',
                    },

                    training_volume: {
                        title: 'Maximum Training Volume',
                        hours_per_week: 'h / week',
                        warning_msg: 'Training Volume Warning!',

                        modal: {
                            title: 'Maximum Training Volume',
                            success_msg: 'Maximum training volume has successfully been updated',
                            confirm_btn: 'Confirm'
                        }
                    },

                    availability: {
                        title: 'Availability',
                        warning_msg: 'Optimal availability recommendations are not met currently. Therefore, TRIQ might not be able to provide ideal training suggestions.',

                        modal: {
                            success_msg: 'Availability has successfully been updated',
                        }
                    }
                },

                availability: {
                    previous_btn: 'Back',

                    title: 'Adjust default availability'
                }
            },

            zones_and_thresholds: {
                previous_btn: 'Back',
                title: 'Zones & Threshold',
                loading: 'Loading …',

                yellow_numbers_hint: 'Yellow numbers are based on average population thresholds.',

                training_zones: {
                    title: 'Zones',
                    rpe: 'RPE',
                    hr: 'HR',
                    pace: 'Pace',
                    power: 'Power',

                    swim: {
                        slow: 'Slow',
                        tempo: 'Medium',
                        threshold: 'Fast',
                        fast: 'All Out'
                    },

                    cycle: {
                        endurance: 'Aerobic',
                        tempo: 'Tempo',
                        threshold: 'Threshold',
                        vo2max: 'VO2max',
                        anaerobic: 'Anaerobic'
                    },

                    run: {
                        endurance: 'Aerobic',
                        tempo: 'Tempo',
                        threshold: 'Threshold',
                        vo2max: 'VO2max',
                        anaerobic: 'Anaerobic'
                    }
                },

                thresholds: {
                    swim: {
                        title: 'Swim Threshold',
                        pace: 'Pace',
                        last_performance_test: 'Last performance test: {date}',
                        last_user_input: 'Last manual inputs: {date}',
                        csp: 'Upper',

                        modal: {
                            title: 'Adjust Critical Swim Pace (CSP)',
                            last_performance_test: 'Last performance test: {date}',
                            last_user_input: 'Last manual inputs: {date}',

                            form: {
                                input: {
                                    pace50m: {
                                        label: 'Pace on 50m',
                                        placeholder: 'm:ss'
                                    },
                                    pace400m: {
                                        label: 'Pace on 400m',
                                        placeholder: 'm:ss'
                                    },
                                    css: {
                                        label: '',
                                        placeholder: 'm:ss'
                                    }
                                }
                            },

                            warning_msg: 'Changes can significantly impact your workout strength, so consider before making any.',
                            confirm_btn: 'Confirm',
                            cancel_btn: 'Cancel',

                            failed_msg: 'Failed to update swimming threshold. Please try again later.',
                            success_msg: 'Swimming threshold has successfully been updated'
                        }
                    },
                    cycle: {
                        title: 'Bike Threshold',
                        heartrate: 'Heart rate',
                        power: 'Power',
                        last_performance_test: 'Last performance test: {date}',
                        last_user_input: 'Last manual inputs: {date}',
                        upper: 'Upper',
                        lower: 'Lower',

                        modal: {
                            title: 'Adjust Cycle data',
                            title_upper_heart_rate: 'Adjust Upper Heart Rate Threshold',
                            title_lower_heart_rate: 'Adjust Lower Heart Rate Threshold',
                            title_upper_power: 'Adjust Upper Power Threshold',
                            title_lower_power: 'Adjust Lower Power Threshold',
                            last_performance_test: 'Last performance test: {date}',
                            last_user_input: 'Last manual inputs: {date}',

                            form: {
                                input: {
                                    heartRate: {
                                        label: '',
                                        placeholder: ''
                                    },
                                    power: {
                                        label: '',
                                        placeholder: ''
                                    }
                                }
                            },

                            warning_msg: 'Changes can significantly impact your workout strength, so consider before making any.',
                            confirm_btn: 'Confirm',
                            cancel_btn: 'Cancel',

                            failed_msg: 'Failed to update cycling threshold. Please try again later.',
                            success_msg: 'Cycling threshold has successfully been updated'
                        }
                    },
                    run: {
                        title: 'Run Threshold',
                        heartrate: 'Heart rate',
                        pace: 'Pace',
                        last_performance_test: 'Last performance test: {date}',
                        last_user_input: 'Last manual inputs: {date}',
                        upper: 'Upper',
                        lower: 'Lower',

                        modal: {
                            title: 'Adjust Run data',
                            title_upper_heart_rate: 'Adjust Upper Heart Rate Threshold',
                            title_lower_heart_rate: 'Adjust Lower Heart Rate Threshold',
                            title_upper_pace: 'Adjust Upper Pace Threshold',
                            title_lower_pace: 'Adjust Lower Pace Threshold',
                            last_performance_test: 'Last performance test: {date}',
                            last_user_input: 'Last manual inputs: {date}',

                            form: {
                                input: {
                                    heartRate: {
                                        label: '',
                                        placeholder: ''
                                    },
                                    pace: {
                                        label: '',
                                        placeholder: 'm:ss'
                                    }
                                }
                            },

                            warning_msg: 'Changes can significantly impact your workout strength, so consider before making any.',
                            confirm_btn: 'Confirm',
                            cancel_btn: 'Cancel',

                            failed_msg: 'Failed to update running threshold. Please try again later.',
                            success_msg: 'Running threshold has successfully been updated'
                        }
                    }
                }
            },

            hr_measurement_trends: {
                previous_btn: 'Profile',
                title: 'HR-Measurement Trends',
                tabs: {
                    HeartRate: 'HR-Variability',
                    RMSSD: 'Rest-HR',

                    '1month': '1 Month',
                    '3months': '3 Months'
                },

                today: 'Today',
                day7trend: '7-Day Trend',
                day3trend: '3-Day Trend',

                loading: 'Loading …',

                hrv: {
                    success: {
                        title: 'Your HRV Trend is within your individual range',
                        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                    },
                    danger: {
                        title: 'Your HRV Trend is trending down',
                        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                    }
                },

                rhr: {
                    success: {
                        title: 'Your RHR Trend is within your individual range',
                        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                    },
                    danger: {
                        title: 'Your RHR Trend is trending down',
                        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                    }
                },

                info: {
                    title: 'Understanding your HR-Trends',
                    hrv: {
                        title: 'HR-Variability',
                        description: 'We are using a 60 day normal vs 7 day baseline to asses your recovery from RMSSD. For example, if your 7 day baseline/average drops below the band of values defined by your 60 day normal, your recovery is indicated to be low. The normal band is defined via the 60 day average plus/minus the 60 day standard deviation.'
                    },
                    rhr: {
                        title: 'Rest-HR',
                        description: 'We are using a 30 day normal vs a 3 day baseline to asses your recovery from your resting heart rate. For example, if your 3 day average is above the band defined by your 30 day normal, your recovery might be low.'
                    }
                }
            },

            equipment: {
                previous_btn: 'Profile',
                title: 'My Equipment',
                description: 'Please choose your equipment from the list. We will adapt our service in line with your equipment.',
                save_btn: 'Save'
            }
        },

        settings: {
            menu: {
                item: {
                    notification_preferences: 'Notification Preferences',
                    third_party_connections: '3rd Party Connections',
                    units_of_measurement: 'Units of Measurement',

                    support: 'Support and Feedback',
                    faq: 'FAQ',
                    glossary: 'Glossary',
                    privacy_terms: 'Privacy & Terms',
                    privacy: 'Privacy Policy',
                    terms: 'Terms & Conditions',
                    about: 'About TRIQ'
                }
            },

            communication: {
                title: 'Communication',
                previous_btn: 'Back',
                push_notifications: {
                    title: 'Pop-Up Notifications',
                    checkbox_label: 'allow Pop-Up Notifications',
                    description: '<p>I\'m happy to receive pop-up notifications on my mobile device. Such information can contain</p><br> - New threshold detection<br> - Fatigue warnings<br> - Plan adaptions<br> - Coaching reminders<br> - etc.'
                },
                email_notifications: {
                    title: 'Email Notifications',
                    checkbox_label: 'allow email notifications',
                    description: '<p>I\'m happy to receive emails for TRIQ to occasionally keep me informed about new offers and general information.</p><p>ATTENTION: System relevant emails like maintenance, subscription issues, health or coaching warning are excluded from this setting</p>'
                }
            },

            third_party_connection: {
                title: '3rd Party Connection',
                previous_btn: 'Back',
                info_hint: 'For now we only support GARMIN®. We are actively working on adding other 3rd party services soon.',
                not_connected_hint: '<div>Until a connection to a 3rd party sport tracking platform is established:</div><ul style="margin-bottom: 0"><li>We won\'t be able to sync and analyze any data</li><li>We can still provide a training plan based on previous data but it won\'t be updated and it won\'t be accurate!</li></ul>',

                garmin: {
                    disconnect: {
                        title: 'Disconnect',
                        cancel_btn: 'Cancel',
                        disconnect_btn: 'Disconnect',

                        data: {
                            title: 'Your Data',
                            description: 'We won’t be able to sync and analyse any data until a connection to GARMIN® is established.',
                        },

                        plan: {
                            title: 'Your Plan',
                            description: 'We can still provide a training plan based on previous data but it won’t be updated until a connection is established again.'
                        }
                    }
                }
            },

            units_of_measurement: {
                title: 'Units of Measurement',
                previous_btn: 'Back',
                back_btn: 'Back',
                general: {
                    title: 'General',
                    unit_system: 'Unit System'
                },
                swimming_garmin_sync: {
                    title: 'Swimming Garmin Sync',
                    intensity_description: 'Intensity Description',
                    pool_length: 'Pool Length'
                },
                cycling_garmin_sync: {
                    title: 'Cycling Garmin Sync',
                    endurance: 'Aerobic Workouts',
                    interval: 'Interval Workouts',
                    indoor: 'Indoor Workouts',
                },
                running_garmin_sync: {
                    title: 'Running Garmin Sync',
                    endurance: 'Aerobic Workouts',
                    interval: 'Interval Workouts'
                },

                edit: {
                    unit: {
                        previous_btn: 'Units of Measurement',
                        label: 'Unit system',
                        success_msg: 'Unit system has successfully been updated',
                        failed_msg: 'Failed to update unit system'
                    },
                    intensity_description: {
                        previous_btn: 'Units of Measurement',
                        label: 'Swimming Intensity Description',
                        success_msg: 'Swimming intensity description has successfully been updated',
                        failed_msg: 'Failed to update swimming intensity description',
                        hint_msg: 'We recommend selecting “descriptive”, as GARMIN® watch display capabilities are limited and work best this way in our experience.'
                    },
                    pool_length: {
                        previous_btn: 'Units of Measurement',
                        label_metric: 'Pool Length',
                        label_imperial: 'Pool Length (in yards)',
                        success_msg: 'Pool length has successfully been updated',
                        failed_msg: 'Failed to update pool length'
                    },
                    cycling_endurance_measurement_type: {
                        previous_btn: 'Units of Measurement',
                        label: 'Cycling Aerobic Workouts',
                        success_msg: 'Measurement type has successfully been updated',
                        failed_msg: 'Failed to update measurement type',
                        hint_msg: 'We recommend to select Heart Rate for Aerobic workouts.'
                    },
                    cycling_interval_measurement_type: {
                        previous_btn: 'Units of Measurement',
                        label: 'Cycling Interval Workouts',
                        success_msg: 'Measurement type has successfully been updated',
                        failed_msg: 'Failed to update measurement type',
                        hint_msg: 'We recommend to use power for interval workouts.'
                    },
                    cycling_indoor_measurement_type: {
                        previous_btn: 'Units of Measurement',
                        label: 'Cycling Indoor Workouts',
                        success_msg: 'Measurement type has successfully been updated',
                        failed_msg: 'Failed to update measurement type',
                    },
                    running_endurance_measurement_type: {
                        previous_btn: 'Units of Measurement',
                        label: 'Running Aerobic Workouts',
                        success_msg: 'Measurement type has successfully been updated',
                        failed_msg: 'Failed to update measurement type',
                        hint_msg: 'We recommend to select Heart Rate for Aerobic workouts.'
                    },
                    running_interval_measurement_type: {
                        previous_btn: 'Units of Measurement',
                        label: 'Running Interval Workouts',
                        success_msg: 'Measurement type has successfully been updated',
                        failed_msg: 'Failed to update measurement type',
                        hint_msg: 'We recommend to select Pace for Interval workouts.'
                    }
                },

                garmin_sync_modal: {
                    title: 'We will update your workout details on the app and on your GARMIN® device',
                    description: '',
                    confirm_btn: 'Got it',
                    skip_hint: 'Don\'t show this again'
                }
            },

            support_and_feedback: {
                title: 'Support & Feedback',
                previous_btn: 'Back',
                support: {
                    title: 'Support',
                    description: 'Please get in touch with our support team if you encounter a problem with the app. We’ll come back to you as soon as possible.',
                    action_btn: 'Contact'
                },
                feedback: {
                    title: 'Feedback',
                    description: 'Feel free to share your ideas with our product team. We appreciate your input that will help to improve our product.',
                    action_btn: 'Share'
                }
            },

            faq: {
                title: 'FAQ',
                previous_btn: 'Profile',
                update_hint: 'Last update:'
            },

            glossary: {
                title: 'Glossary',
                previous_btn: 'Profile',
            }
        },

        thresholds: {
            info: {
                running_upper_hr_modal: {
                    title: 'Upper Threshold (bpm)',
                    description: 'Enter the data for one of the following options, if known: <ul class="info-list"><li>The result of a lab-test that represents your current fitness situation.</li><li>The avg. heart rate of the middle 10-minute segment of a recently done 20-minute run test (all out field test).</li><li>The avg. heart rate of a run (between 5-10k) that was performed as fast as possible.</li></ul>',
                    confirm_btn: 'Got it'
                },
                running_lower_hr_modal: {
                    title: 'Lower Threshold (bpm)',
                    description: 'Enter the data for one of the following options, if known: <ul class="info-list"><li>The result of a lab-test that represents your current fitness situation.</li><li>The avg. heart rate of a run (longer then 5k) that was performed on a low intenstiy, that you think you can hold "forever".</li></ul>',
                    confirm_btn: 'Got it'
                },
                running_upper_pace_modal: {
                    title: 'Upper Threshold (min/km)',
                    description: 'Enter the data for one of the following options, if known: <ul class="info-list"><li>The result of a lab-test that represents your current fitness situation.</li><li>The avg. pace of the middle 10-minute segment of a recently done 20-minute run test (all out field test), that you then make 5% slower.</li></ul>',
                   confirm_btn: 'Got it'
                },
                running_lower_pace_modal: {
                    title: 'Lower Threshold (min/km)',
                    description: 'Enter the data for the following option, if known: <ul class="info-list"><li>A lab-test that represents your current fitness situation, if known.</li></ul>',
                    confirm_btn: 'Got it'
                },
                cycling_upper_hr_modal: {
                    title: 'Upper Threshold (bpm)',
                    description: 'Enter the data for one of the following options, if known: <ul class="info-list"><li>The result of a lab-test that represents your current fitness situation.</li><li>The avg. heart rate of the middle 10-minute segment of a recently done 20-minute FTP-Test.</li></ul>',
                   confirm_btn: 'Got it' 
                },
                cycling_lower_hr_modal: {
                    title: 'Lower Threshold (bpm)',
                    description: 'Enter the data for the following option, if known: <ul class="info-list"><li>The data of a lab-test that represents your current fitness situation.</li></ul>',
                    confirm_btn: 'Got it'
                },
                cycling_upper_power_modal: {
                    title: 'Upper Threshold (Watts)',
                    description: 'Enter the data for one of the following options, if known: <ul class="info-list"><li>The result of a lab-test that represents your current fitness situation.</li><li>The avg. power (Watts) of the middle 10-minute segment of a recently done 20-minute FTP-Test and then reduce it by 5%.</li></ul>',
                    confirm_btn: 'Got it'
                }, 
                cycling_lower_power_modal: {
                    title: 'Lower Threshold (Watts)',
                    description: 'Enter the data for the following option, if known: <ul class="info-list"><li>The data of a lab-test that represents your current fitness situation.</li></ul>',
                    confirm_btn: 'Got it'
                 },   
            }
        }
    },

    support: {
        title: 'Get Support',
        description: 'Please get in touch if you encounter problems using our app.',
        submit_btn: 'Submit',

        form: {
            input: {
                email: {
                    label: 'Email',
                    placeholder: ''
                },
                subject: {
                    label: 'Subject',
                    placeholder: ''
                },
                description: {
                    label: 'Description',
                    placeholder: ''
                }
            }
        }
    },

    workout: {
        previous_btn: 'Previous',

        duration: 'Duration',
        distance: 'Distance',
        intensity: 'Training Focus',
        swimtime: 'Swim Time',
        time: 'Time',
        focus: 'Focus',
        workout_focus: 'Workout Focus',
        lead_unit: 'Lead Unit',
        execution_status: 'Execution Status',

        estimated_time: 'Est. Time',
        estimated_distance: 'Est. Distance',

        pool_length: 'Pool Length',
        intensity_description: 'Intensity Description',
        intensity_description_descriptive: 'Text',
        intensity_description_pace: 'Pace',
        garmin_sync: 'GARMIN® Sync',

        skip_btn: 'Skip workout',
        unskip_btn: 'Unskip workout',

        no_hrv_label: 'No HRV',

        print: {
            coming_soon: {
                title: 'The print version of the workouts will be available soon',
                description: '',
                confirm_btn: 'Got it'
            }
        },

        lead_unit_modal: {
            title: 'Lead Unit',

            confirm_btn: 'Change only for this workout',
            confirm_all_btn: 'Change for all workouts',

            failed_msg: 'Failed to update lead unit',
        },

        garmin_sync_modal: {
            title: 'We pushed the information to Garmin®',
            description: '<strong style="color: #FCB326">Important:</strong> Make sure you are synchronizing your wearable device.',

            confirm_btn: 'Got it',
            skip_hint: 'Don\'t show this again'
        },

        feedback_completed: 'Session feedback received',

        session_feedback: {
            title: 'Session Feedback',

            step1: {
                title: 'Did you execute the training as planned regarding duration and intensity?',

                option: {
                    yes: 'Yes, more or less',
                    no: 'No, I didn’t'
                }
            },

            step2: {
                title: 'How intense did this session feel?',
                intensity: {
                    item1: {
                        title: 'no activity',
                        description: 'I am sitting on a sofa, watching TV but not sleeping'
                    },
                    item2: {
                        title: 'very light',
                        description: 'could keep this effort forever'
                    },
                    item3: {
                        title: 'light',
                        description: 'feel good, breathing activity and effort is getting noticeable but is manageable'
                    },
                    item4: {
                        title: 'moderate',
                        description: 'sweating a bit; can keep longer conversation with this effort'
                    },
                    item5: {
                        title: 'somewhat hard',
                        description: 'moderate sweating; can keep short conversation that is somewhat challenging'
                    },
                    item6: {
                        title: 'hard',
                        description: 'my breath is getting shorter; only talk in single sentence'
                    },
                    item7: {
                        title: 'very hard',
                        description: 'strong sweating; normal conversation not possible, feels unconfortable'
                    },
                    item8: {
                        title: 'extremely hard',
                        description: 'can only speak in \'few word sentences\'; very short breathing'
                    },
                    item9: {
                        title: 'almost maximal',
                        description: 'effort sustainable only for few seconds (<30s); I see light at the end of the tunnel'
                    },
                    item10: {
                        title: 'maximal',
                        description: 'all out; unable to sustain; I am nearly dead'
                    }
                }
            },

            step3: {
                title: 'How did the workout duration feel?',

                duration: {
                    item1: {
                        title: 'much too short'
                    },
                    item2: {
                        title: 'a little too short'
                    },
                    item3: {
                        title: 'good'
                    },
                    item4: {
                        title: 'a bit too long'
                    },
                    item5: {
                        title: 'much too long'
                    }
                }
            },

            continue_btn: 'Continue',
            submit_btn: 'Send Feedback',
        },

        see_details: 'SEE DETAILS',
        more_details: 'MORE DETAILS',

        details: {
            graph: {
                title: 'Workout Graph',
            },
            details: {
                title: 'Workout Details',
                max_hr: 'Max HR',
                avg_hr: 'Avg. HR',
                max_pace: 'Max Pace',
                avg_pace: 'Avg. Pace',
                estimated_elevation: 'Elevation',
                duration: 'Workout Duration',
                strokes: 'Strokes per lane',
                pool_length: 'Pool Length',
                max_power: 'Max Watts',
                avg_power: 'Avg. Watts',
                hrv: 'HRV',
                hrv_data_true: 'Data Logged',
                hrv_data_false: 'No Data',
                hrv_hint: {
                    description: 'Processing HRV Data will help us to better plan your upcoming trainings.',
                    action: 'Why Is my HRV Data not Logged?'
                }
            },
            zones: {
                title: 'Time Spent in {leadUnit} Zones'
            },

            description: {
                title: 'Workout Description'
            }
        },

        how_it_works_btn: 'How it works',
        about_css_btn: 'About CSS',
        about_ftp_btn: 'About FTP',
        about_crp_btn: 'About CRP',

        info: {
            how_it_works: {
                ftp: {
                    header: 'FTP-Test',
                    title: 'How it works',
                    description: 'To find your upper threshold do a 20-minute time trial. Again, it should be done <strong>as if it was a race for the entire 20 minutes</strong>. Go hard from the very beginning,  but be aware that most people doing this test go too hard the first few minutes and then gradually slow down for the remainder. That will give you inaccurate results. The more times you do this test the more accurate your upper threshold is likely to become as you will learn to pace yourself better at the start.'
                },
                css: {
                    header: 'CSS-Test',
                    title: 'How it works',
                    description: 'To test your current CSS pace you need to swim a 400 and 200 Time Trial within the same session. Ideally, get a friend or coach to time you and record your 100 splits and strokes per minute. Failing that, simply record the 400 and 200 times yourself. Go hard from the very beginning, strong push off the wall but no diving'
                },
                crp: {
                    header: 'CRP-Test',
                    title: 'How it works',
                    description: 'To find your upper threshold do a 20-minute time trial. Again, it should be done <strong>as if it was a race for the entire 20 minutes</strong>. Go hard from the very beginning,  but be aware that most people doing this test go too hard the first few minutes and then gradually slow down for the remainder. That will give you inaccurate results. The more times you do this test the more accurate your upper threshold is likely to become as you will learn to pace yourself better at the start.'
                }
            },
            about: {
                ftp: {
                    header: 'FTP',
                    title: 'Functional Threshold Power',
                    description: 'Functional Threshold Power (FTP) is a measure of an athlete\'s sustainable effort in cycling for approximately one hour. It helps determine personalized training zones and guides intensity levels for workouts.'
                },
                css: {
                    header: 'CSS',
                    title: 'Critical Swim Speed',
                    description: 'To test your current CSS pace you need to swim a 400 and 200 Time Trial within the same session. Ideally, get a friend or coach to time you and record your 100 splits and strokes per minute. Failing that, simply record the 400 and 200 times yourself. Go hard from the very beginning, strong push off the wall but no diving'
                },
                crp: {
                    header: 'CRP',
                    title: 'Critical Run Pace',
                    description: 'Critical Swim Speed (CSS) is a measure commonly used in swimming to determine an individual\'s sustainable pace over a given distance.It represents the swimming speed at which an athlete can maintain a steady state without excessive fatigue for an extended period.CSS is often utilized as a reference point for training and race strategy.'
                }
            },
            zones: {
                running: {
                    title: 'Your Running Zones',
                    description: 'These zones help you to execute your training in the most effective way. We will adapt your zones as your progress improves.'
                },
                swimming: {
                    title: 'Your Swimming Zones',
                    description: 'These zones help you to execute your training in the most effective way. We will adapt your zones as your progress improves.'
                },
                cycling: {
                    title: 'Your Cycling Zones',
                    description: 'These zones help you to execute your training in the most effective way. We will adapt your zones as your progress improves.'
                },
            }
        },

        skip_recommended: {
            alert: {
                title: 'Skip recommended',
                description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
            }
        },

        skip: {
            modal: {
                description: 'Are you sure you want to skip this workout?',
                yes_btn: 'Yes',
                no_btn: 'No',
            }
        },

        move: {
            modal: {
                description: 'Should we move this workout instead?',
                confirm_btn: 'Confirm',

                suggestion: {
                    skip: 'Skip Workout'
                }
            }
        },

        strength: {
            exercises: {
                title: 'Exercises'
            },
            complete_btn: 'Workout completed'
        },

        multisport: {
            time: 'Time',
            duration: 'Duration',
            distance: 'Distance',
            avg_pace: 'Avg. Pace',
            avg_speed: 'Avg. Speed',
            avg_watt: 'Avg. Watt',
            avg_hr: 'Avg. Heart Rate',
            target_time: 'Target Time',
            finishing_time: 'Finishing Time',

            luck_hint: 'The TRIQ Team wishes you good luck for the race!',

            session: {
                transition: {
                    title: 'Transition'
                }
            },

            other: {
                title: 'Other',
                elevation: 'Estimated Elevation'
            }
        },

        not_found: {
            title: 'Whoops!',
            subtitle: 'Workout not found',
            description: 'The workout you are looking for is not available. Please try again later.'
        },

        item: {
            action: {
                skip_btn: 'Skip',
                unskip_btn: 'Unskip'
            }
        }
    },

    plan: {
        index: {
            subscribe: {
                subscribe_btn: 'Subscribe now',
                subscribe_hint: 'You need to subscribe to see workouts'
            },

            status: {
                green: 'Ready to start the next workout!',
                yellow: 'We recommend you to wait a bit more to start your next workout',
                red: 'We recommend you to reduce the intensity for today’s run workout'
            },

            checkin_btn: 'Assess Daily Readiness',
            muscle_fatigue_btn: 'Report Muscle Fatigue',
            short_term_availability_btn: 'Adjust short term availability',

            overview: {
                today: 'Today\'s workouts ({count})',
                raceday: 'Race Day',
                edit_plan_btn: 'Edit Plan',

                status: {
                    proceed: {
                        title: 'Proceed as planned',
                        description: 'TRIQ\'s recovery model predicts good recovery so you should feel reasonable refreshed and ready for training. However we do not have any briefing data from you, so we are unable to make any acute conclusions.'
                    },
                    takecare: {
                        title: 'Reduce intensity, Take it easy',
                        description: 'Besides an optimal recovery estimation the daily briefing shows multiple HRV measurements out of the 60-day corridor. You might consider reducing intensity or taking it easy for some workouts.'
                    },
                    skip: {
                        title: 'Skip workout',
                        description: 'TRIQ\'s recovery model detected a much poorer recovery then expected. The daily briefing shows multiple HRV measurements out of the 60-day corridor. We recommend to skip the marked workouts today.'
                    },
                    rest: {
                        title: 'Consider to take a rest day',
                        description: 'Besides an optimal recovery estimation the daily briefing shows that your 7-day HRV baseline dropped below the 60-day reference corridor, indicating a suboptimal autonomic situation. For that reason we recommend to take a complete rest day.'
                    },
                    noworkouts: {
                        title: 'No workouts planned today',
                        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
                    },
                    plangeneration: {
                        title: 'Building your personalised plan',
                        description: 'We are processing your data and building your personalised plan, this could take a few minutes.'
                    },
                    error: {
                        title: 'Plan generation failed',
                        description: 'We are sorry, but we were unable to generate your plan. Please adapt your training parameters in the app settings.'
                    },
                    userneedsgoal: {
                        title: 'Please select a goal',
                        description: 'In order to create a new training plan for you, we need a new goal from you.',
                        selectgoal_btn: 'Select goal'
                    }
                }
            },

            recovery: {
                cardio: 'Cardio',
                arms: 'Arms',
                arms_subtitle: '(Muscles & Joints)',
                legs: 'Legs',
                legs_subtitle: '(Muscles & Joints)',

                expected: {
                    title: 'Your Recovery values are as expected',
                    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                },
                notexpected: {
                    title: 'Your Recovery is lower than expected',
                    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
                },

                legend: {
                    recovery: 'Recovery',
                    expected: 'Expected'
                }
            },

            briefing: {
                repeat_btn: 'Repeat briefing',

                repeat: {
                    title: 'You already made a briefing today!',
                    description: 'Repeating the briefing will overwrite the previous result.',
                    continue_btn: 'Continue',
                    cancel_btn: 'Cancel'
                },

                none: {
                    title: 'No results for today',
                    description: 'Go back to the dashboard and start your daily briefing to see your results',
                    start_btn: 'Assess Daily Readiness'
                },

                coming_soon: {
                    title: 'Coming soon',
                    description: 'We are working hard to bring you this feature soon. Stay tuned!',
                    confirm_btn: 'Got it'
                }
            },

            assure_garmin_sync: {
                modal: {
                    title: 'Make sure TRIQ has<br>all your workout data',
                    description: 'If you know you will skip a workout, you can select SKIP THIS WORKOUT in the workout details. This way, we can accordingly update upcoming training recommendations immediately.',
                    skip_hint: 'Don\'t show this again',
                    confirm_btn: 'Got it'
                }
            }
        },

        workout: {
            none: {
                title: 'No workouts today',
                description: 'Have a great day and get some rest.'
            }
        },

        recalculation: {
            modal: {
                title: 'Plan update recommended',
                description: 'Based on your changes, we would recommend you to update your training plan.',
                confirm_btn: 'Update plan',
                cancel_btn: 'No, thanks',
                skip_hint: 'Don\'t show this again'
            },

            repeated: {
                modal: {
                    title: 'Repeated Recalculation',
                    description: 'You can recalculate as often as you like, though the content of the plan will not change fundamentally, only the distribution of the workouts.',
                    confirm_btn: 'Got it',
                    skip_hint: 'Don\'t show this again'
                }
            }
        },

        do_not_recalculate_modal: {
            title: 'Do not recalculate...',
            description: 'We leave everything exactly as it is for the selected period.',
            confirm_btn: 'Confirm',
        },

        validation: {
            info: {
                modal: {
                    confirm_btn: 'Got it',
                }
            },

            confirm: {
                modal: {
                    title: 'Warning detected',
                    description: 'We have detected one or more warnings in your training plan.',
                    cancel_btn: 'Go back',
                    confirm_btn: 'Continue anyway'
                }
            }
        },

        edit: {
            info: {
                modal: {
                    title: 'Drag & Drop to Move Workouts',
                    description: 'Drag the workout you want to move and drop it to a day that better suits your schedule. If we detect a problem with your action, we will let you know.',
                    confirm_btn: 'Got it',
                    skip_hint: 'Don\'t show this again'
                }
            },

            failed_msg: 'Failed to update training plan',
        }
    },

    forecast: {
        title: '2-Weeks',
        recalculate_next_week_btn: 'Recalculate',
        edit_btn: 'Edit',
        training_volume: 'This Week\'s Volume: <span class="text-white">{completed}</span> / {planned} h',
        training_volume_next: 'Next Week\'s Volume: <span class="text-white">{completed}</span> / {planned} h',
        brick_workouts_hint: '<strong>Brick training:</strong> Execution of two workouts back-to-back with no pause. It teaches athletes the physical and mental switch between sports.',
        today: 'Today',

        recalculate_next_week: {
            modal: {
                title: 'Replanning next week',
                description: '<p>Please be aware that there might be rearrangements of the workout schedule and workout content for the upcoming week.</p><p><strong>ATTENTION:</strong> This action cannot be undone!</p>',
                confirm_btn: 'Recalculate next week',
                undo_hint: 'You cannot undo this action',

                success_msg: 'The regeneration of your training plan has started. Please note that it may take a few minutes for your training plan to be updated.',
                failed_msg: 'Failed to recalculate next week. Please try again later.'
            }
        },

        recalculation: {
            loading: 'Replanning'
        },

        status: {
            green: {
                description: 'You are within the expected recovery and should be able to do the upcoming workouts'
            },
            red: {
                description: 'Due to the readiness situation we recommend you to update your plan'
            }
        },

        current: {
            title: 'Current Recovery'
        },

        recovery: {
            cardio: 'Cardio',
            arms_muscle: 'Arms (Muscle)',
            arms_structure: 'Arms (Structure)',
            legs_muscle: 'Legs (Muscle)',
            legs_structure: 'Legs (Structure)',
        },

        legend: {
            required: 'Required',
            ready: 'Ready',
            tight: 'It´s tight',
            conflict: 'Conflict'
        },

        training_phases: {
            previous_btn: 'Back',

            name: {
                Foundation: 'Foundation Phase',
                Specific: 'Specific Phase',
                Taper: 'Taper Phase',
                PostRace: 'Post Race Phase',
                RaceDay: 'Race Day',
                Off: 'Off Phase'
            },

            foundation_phase: {
                title: 'Foundation Phase',
                description: 'The Foundation phase establishes base fitness and endurance through low-intensity, high-volume workouts. It also focuses on enhancing aerobic capacity, strength, and sport-specific techniques, essential for injury prevention and preparing athletes for more intense future training. It sets a solid fitness foundation necessary for handling tougher workouts ahead.'
            },

            specific_phase: {
                title: 'Specific Phase',
                description: 'In the Specific phase, the training plan starts to focus on race-specific workouts. It emphasizes workouts that simulate race conditions to improve efficiency and speed across swimming, cycling, and running.'
            },

            taper_phase: {
                title: 'Taper Phase',
                description: 'The Taper phase is critical for peak race performance. Training volume is reduced significantly to allow the body to recover and strengthen, paradoxically increasing performance potential. It prepares athletes by refining pacing strategies and transitions, ensuring readiness and confidence for race day. Also it focuses on fine-tuning for optimal condition at the race, leveraging rest for physical and psychological benefits, ensuring athletes are primed to perform at their best.'
            },

            post_race_phase: {
                title: 'Post Race Phase',
                description: 'The Post-Race phase emphasizes recovery and analysis after a triathlon. Athletes engage in rest and light activities to aid physical and mental recovery, while also evaluating their race performance to pinpoint improvements. This period is vital for rejuvenation, preventing burnout, and preparing for future training with insights from the race. It supports long-term growth and readiness for upcoming events.'
            },

            postrace_phase: {
                title: 'Post Race Phase',
                description: 'The Post-Race phase emphasizes recovery and analysis after a triathlon. Athletes engage in rest and light activities to aid physical and mental recovery, while also evaluating their race performance to pinpoint improvements. This period is vital for rejuvenation, preventing burnout, and preparing for future training with insights from the race. It supports long-term growth and readiness for upcoming events.'
            }
        },

        plan: {
            edit: {
                confirm_btn: 'Confirm',
                next_week: 'NEXT WEEK',
                recalculate_btn: '✨ Recalculate',
            },

            recalculate: {
                title: 'Plan Recalculation',
                description: 'Lock the workouts that you want to keep',
                confirm_btn: 'Recalculate plan',
                skip_hint: 'Do not recalculate',
                skip_option: {
                    0: 'current day',
                    1: 'next 2 days',
                    2: 'next 3 days',
                    3: 'next 4 days',
                    4: 'next 5 days',
                    5: 'next 6 days'
                }
            }
        }
    },

    roadmap: {
        title: 'Roadmap',

        chart: {
            max_volume: 'Max. Volume'
        },

        goal: {
            today: 'today',
            tomorrow: 'tomorrow',
            days_ago: '{days} days ago',
            days_in: 'in {days} days',
        },

        phases: {
            title: 'Training Phases',
            current: 'Current',
        },

        total_volume: 'Volume at phase end: {volume}h'
    },

    sean_ellis: {
        title: 'How would you feel if you could no longer use TRIQ?',
        description: '',
        answer: {
            very_disappointed: 'Very disappointed',
            somewhat_disappointed: 'Somewhat disappointed',
            not_disappointed: 'Not disappointed (I don’t find it useful)',
            i_will_no_longer_use_this_product: 'N/A - I no longer use TRIQ'
        },
        confirm_btn: 'Confirm',

        feedback: {
            title: 'Share your feedback and thoughts about the app.',
            description: '',
            input: {
                placeholder: 'Type your text here'
            }
        },

        send_btn: 'Send feedback',
        skip_btn: 'Skip'
    },

    compatibility: {
        incompatible_app_version_modal: {
            ForceUpdate: {
                title: 'Update required',
                description: 'We\'ve made significant improvements! However, your current app version is no longer supported. To continue enjoying the app\'s features and ensure a seamless experience, please update to the latest version. By updating, you\'ll gain access to new features, enhanced security, and improved performance. The process is quick and easy – just visit the app store and click \'Update\'.',
                update_btn: 'Update now'
            },
            UpdateUrgent: {
                title: 'Update available',
                description: 'Good news! A new update is available for the app. While your current version will continue to function, you might encounter moments where it doesn\'t perform at its best.To ensure optimal performance and a smoother experience, we recommend updating to the latest version at your earliest convenience.',
                update_btn: 'Update now'
            },
            UpdateRecommended: {
                title: 'Update recommended',
                description: 'The latest app update is ready and waiting for you. While the app is currently running smoothly, you\'re missing out on some exciting new features that could make your experience even better. Update now to unlock these enhancements and take full advantage of all our app has to offer.',
                update_btn: 'Update now'
            },
            UpdateAvailable: {
                title: 'Update available',
                description: 'The latest app update is ready and waiting for you. While the app is currently running smoothly, you\'re missing out on some exciting new features that could make your experience even better. Update now to unlock these enhancements and take full advantage of all our app has to offer.',
                update_btn: 'Update now'
            }
        },
    },

    validation: {
        messages: {
            alpha: "The {field} field may only contain alphabetic characters",
            alpha_num: "The {field} field may only contain alpha-numeric characters",
            alpha_dash: "The {field} field may contain alpha-numeric characters as well as dashes and underscores",
            alpha_spaces: "The {field} field may only contain alphabetic characters as well as spaces",
            between: "The {field} field must be between 0:{min} and 1:{max}",
            confirmed: "The {field} field confirmation does not match",
            digits: "The {field} field must be numeric and exactly contain 0:{length} digits",
            dimensions: "The {field} field must be 0:{width} pixels by 1:{height} pixels",
            email: "The {field} field must be a valid email",
            excluded: "The {field} field is not a valid value",
            ext: "The {field} field is not a valid file",
            image: "The {field} field must be an image",
            integer: "The {field} field must be an integer",
            length: "The {field} field must be 0:{length} long",
            max_value: "The {field} field must be 0:{max} or less",
            max: "The {field} field may not be greater than 0:{length} characters",
            mimes: "The {field} field must have a valid file type",
            min_value: "The {field} field must be 0:{min} or more",
            min: "The {field} field must be at least 0:{length} characters",
            numeric: "The {field} field may only contain numeric characters",
            one_of: "The {field} field is not a valid value",
            regex: "The {field} field format is invalid",
            required_if: "The {field} field is required",
            required: "The {field} field is required",
            size: "The {field} field size must be less than 0:{size}KB",

            min_duration_seconds: 'Value must be greater than {0} seconds',
            max_duration_seconds: 'Value must be less than {0} seconds',
            min_value_seconds: 'Value must be greater than {0} seconds',
            max_value_seconds: 'Value must be less than {0} seconds',

            training_volume_capability_min_long_session: 'Your current long session capability is too long for the requested volume.',
            long_session_capability_min_training_volume: 'Your current weekly training capability is too low for the requested duration.',
            long_session_capability_min_training_volume_onboarding: 'Your average longest session cannot exceed your average training volume. Please choose a lower value or go back and increase the average weekly training volume.'
        },

        names: {
            heightInCentimeters: 'Height',
            weightInKilos: 'Weight',

            username: 'Nickname',
            firstname: 'First Name',
            lastname: 'Last Name',

            heartRate: 'Heart rate',
            'hr.upper': 'Upper HR',
            'hr.lower': 'Lower HR',
            'pace.upper': 'Upper pace',
            'pace.lower': 'Lower pace',
            'power.upper': 'Upper power',
            'power.lower': 'Lower power',

            additionalEmailAddress: 'email'
        }
    },

    api: {
        triq: {
            athlete: {
                gender: {
                    Male: 'Male',
                    Female: 'Female'
                },

                measurementType: {
                    HeartRate: 'Heart Rate',
                    HeartRate_Emoji: '❤️',
                    Power: 'Power',
                    Power_Emoji: '⚡️',
                    Pace: 'Pace',
                    Pace_Emoji: '⏱️',
                    Speed: 'Speed',
                    Speed_Emoji: '⏱️'
                },

                profile: {
                    gender: {
                        options: {
                            Male: 'Born as male',
                            Female: 'Born as female'
                        }
                    },

                    preferences: {
                        preferredUnitSystem: {
                            options: {
                                Metric: 'Metric',
                                Imperial: 'Imperial'
                            }
                        },
                        useVerbalSwimPace: {
                            yes: 'Descriptive',
                            no: 'Pace'
                        },
                        cyclingMeasurementType: {
                            options: {
                                HeartRate: 'Heart Rate',
                                Power: 'Power'
                            }
                        },
                        runningMeasurementType: {
                            options: {
                                HeartRate: 'Heart Rate',
                                Power: 'Power',
                                Pace: 'Pace',
                                Speed: 'Speed'
                            }
                        }
                    },

                    swimmingExperienceTrainingDurationInMonthsOptions: '{months} month| {months} months',
                    swimmingExperienceTrainingDurationInMonthsNone: 'Less than 2 months',

                    swimmingExperienceTrainingVolumeInMetersPerWeekOptions: '{volume}m| {volume}m',
                    swimmingExperienceTrainingVolumeInMetersPerWeekNone: '-',


                    cyclingExperienceTrainingDurationInMonthsOptions: '{months} month| {months} months',
                    cyclingExperienceTrainingDurationInMonthsNone: 'Less than 2 months',

                    cyclingExperienceTrainingVolumeInHoursPerWeekOptions: '{volume}h| {volume}h',
                    cyclingExperienceTrainingVolumeInHoursPerWeekNone: '-',

                    runningExperienceTrainingDurationInMonthsOptions: '{months} month| {months} months',
                    runningExperienceTrainingDurationInMonthsNone: 'Less than 2 months',

                    runningExperienceTrainingVolumeInHoursPerWeekOptions: '{volume}h| {volume}h',
                    runningExperienceTrainingVolumeInHoursPerWeekNone: '-',

                    enduranceSports: {
                        AquaJogging: 'Aqua Jogging',
                        CrossCountrySkiing: 'Cross Country Skiing',
                        CrossTrainer: 'CrossTrainer',
                        Hiking: 'Hiking',
                        IceSkating: 'Iceskating',
                        Kayak: 'Kayak / Kanu',
                        OrientationRunning: 'Orientation Running',
                        Rowing: 'Rowing',
                        SkiTouring: 'Ski touring',
                        SpeedSkating: 'Speed Skating (Road)'
                    },

                    injury: {
                        swimming: 'I tend to swimming injuries',
                        cycling: 'I tend to cycling injuries',
                        running: 'I tend to running injuries',
                    },

                    trainingVolumeOptions: '{volume}h|{volume}h'
                },

                goal: {
                    raceType: {
                        options: {
                            Sprint: 'Sprint',
                            Olympic: 'Olympic',
                            HalfIronman: 'Half Ironman',
                            Ironman: 'Ironman'
                        }
                    },

                    topography: {
                        options: {
                            null: 'Select',
                            Flat: 'Flat',
                            Hilly: 'Hilly'
                        }
                    },

                    aimedTargetTime: {
                        options: {
                            UnderMinimumTimeForRaceType: 'Under {time}',
                            OverMaximumTimeForRaceType: 'Just finish'
                        }
                    }
                }
            },

            availability: {
                dayOfTheWeek: {
                    Monday: 'MON',
                    Tuesday: 'TUE',
                    Wednesday: 'WED',
                    Thursday: 'THU',
                    Friday: 'FRI',
                    Saturday: 'SAT',
                    Sunday: 'SUN'
                },

                validation: {
                    NotEnoughTrainingDays: 'Training Days',
                    NotEnoughTrainingHours: 'Volume',
                    NoTimeForLongWorkout: 'Long Workouts',
                    SwimmingAvailabilityNotHighEnough: 'Volume',
                    NotEnoughSwimmingSlots: 'min. Slots',
                    CyclingAvailabilityNotHighEnough: 'Volume',
                    NotEnoughCyclingSlots: 'min. Slots',
                    NoTimeForLongWorkoutCycling: 'Long Workouts',
                    RunningAvailabilityNotHighEnough: 'Volume',
                    NotEnoughRunningSlots: 'min. Slots',
                    NoTimeForLongWorkoutRunning: 'Long Workouts'
                }
            },

            threshold: {
                unit: {
                    BeatsPerMinute: 'bpm',
                    Watts: 'W',
                    // most of the units are displayed as minutes instead of seconds (which are returned by the api)
                    SecondsPer100Meters: 'min/100m',
                    SecondsPer100Yards: 'min/100yd',
                    SecondsPerKilometer: 'min/km',
                    SecondsPerMile: 'min/mi',

                }
            },

            history: {
                unit: {
                    // most of the units are displayed as miles instead of yards (which are returned by the api)
                    Yards: 'mi',
                    // most of the units are displayed as kilometers instead of meters (which are returned by the api)
                    Meters: 'km'
                }
            },

            workout: {
                unit: {
                    MinutesPerKilometer: 'min/100m',
                    MinutesPerMile: 'min/100yd',
                },

                intensity: {
                    warmup: 'Warm-Up',
                    cooldown: 'Cool-Down',
                }
            }
        }
    },

    component: {
        input: {
            picker: {
                cancel_btn: 'Cancel',
                confirm_btn: 'Confirm'
            }
        }
    }
};